import BackgroundComponent from "../../components/BackgroundComponent";
import Box from "../../components/Box";

function Home() {
  const propertyBackground = "";
  const cssBackground = ``;
  const cssGallery = "lg:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr] gap-3 mt-4 grid-cols-[1fr] ";

  function extractDate(str) {
    if (str.length < 14) {
      throw new Error("La chaîne est trop courte pour contenir une date valide.");
    }

    const year = str.substring(0, 4);
    const month = str.substring(4, 6);
    const day = str.substring(6, 8);

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  function importAll(r) {
    let images = {};
    r.keys().map((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  // image.replace(/.{6}\.(svg|webp|png|jpe?g)$/, "")

  const images = importAll(require.context("../../assets/img", false, /\.(svg|jpe?g|webp|png)$/));
  const tab = [];
  for (const image in images) {
    tab.push({ src: images[image], title: extractDate(image) });
  }

  return (
    <main className=" max-w-[80%] mx-auto ">
      <BackgroundComponent PropertyBackground={propertyBackground} cssBackground={cssBackground} />
      <Box tab={tab} cssGallery={cssGallery} />
    </main>
  );
}

export default Home;
