import { useContext, useEffect, useState } from "react";
import { BackgroundContext } from "../../utils/Context";
import { createPortal } from "react-dom";

function BackgroundComponent({ PropertyBackground, cssBackground }) {
  const { background, toggleBackground, globalState } = useContext(BackgroundContext);
  const [size, setSize] = useState({});

  useEffect(() => {
    toggleBackground(PropertyBackground, cssBackground);
  }, []);

  useEffect(() => {
    const y = window.innerHeight + (window.innerHeight * 10) / 100;
    setSize({
      width: `${window.innerWidth.toString()}px`,
      height: `${y.toString()}px`,
    });
  }, []);

  return (
    <>
      {createPortal(
        <div
          style={
            window.innerWidth < 1024
              ? { width: `${size.width}`, height: `${size.height}` }
              : { width: "100%" }
          }
          className={`
      bckgr 
      pointer-events-none
      overflow-hidden
      lg:absolute fixed  
      left-0
      right-0
      top-0
      bottom-0
      -z-50
      m-0
      bg-no-repeat 
      transition-opacity
      ${background === "" ? "" : background} 
      ${globalState === true ? "ease-in-out duration-[2000ms] opacity-100" : "opacity-0"} 
      ${globalState === false ? "ease-in-out duration-[500ms] opacity-0" : "opacity-100"}`}
        >
          {console.log(size)}
        </div>,
        document.body,
      )}
    </>
  );
}
export default BackgroundComponent;
