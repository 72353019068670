import { useState } from "react";
import { createPortal } from "react-dom";
import Home from "../Home";

function EnterPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [stateTransition, setStatetransition] = useState(false);
  function timer() {
    setIsOpen(true);
    setTimeout(() => {
      setStatetransition(true);
      console.log("fin");
    }, 1000);
  }

  return (
    <>
      {createPortal(
        <>
          <div
            className={`entr  transition-opacity ease-in-out duration-[1000ms] bg-white fixed top-0 left-0 right-0 bottom-0 flex w-[100%] h-[100vh] m-0 ${
              isOpen ? "opacity-0 " : " opacity-100  "
            } ${stateTransition ? "-z-50" : "z-50"} `}
          >
            <div
              className={` fixed top-0 left-0 right-0 bottom-0 flex w-[100%]  m-0   
         justify-center items-center bg-inTheDesert  bg-contain bg-no-repeat bg-center 
        `}
            >
              <div
                className={`${
                  isOpen ? "hidden" : "flex items-center justify-center flex-col text-white"
                }   z-50 `}
              >
                <h1 className="text-4xl ">Monsieur Gelder</h1>
                <button
                  className="my-16 text-4xl transition-transform duration-500  hover:scale-150"
                  onClick={() => timer()}
                >
                  Entrer
                </button>
              </div>
            </div>
          </div>
        </>,
        document.body,
      )}
      <Home />
    </>
  );
}

export default EnterPage;
